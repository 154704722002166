import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { FaStar, FaGraduationCap } from 'react-icons/fa';
import media from 'styled-media-query';
import { MdWork, MdPeople } from 'react-icons/md';
import styled from 'styled-components';
import 'react-vertical-timeline-component/style.min.css';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: auto;

  ${media.lessThan('large')`
    padding: 3rem 0 0 0;

  `}
  .vertical-timeline-element-date {
    color: white;
  }
  ${media.lessThan('large')`
    .vertical-timeline-element-date {
      color: black;
      font-weight: 500;
    }
  `}
`;

const Title = styled.h3`
  font-weight: bold;
  margin: 7px 0;
  color: ${({ color }) => color || 'white'};
  font-size: 20px;
`;

const styles = {
  content: { background: '#f69723', color: 'white' },
  border: { borderTop: '5px solid #f69723', color: 'black' },
  arrow: { borderRight: '7px solid #f69723' }
};

const Timeline = () => (
  <Wrapper>
    <VerticalTimeline>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={styles.content}
        contentArrowStyle={styles.arrow}
        date="2019 - Currently"
        iconStyle={styles.content}
        icon={<MdPeople />}
      >
        <Title>Community Ambassador!</Title>
        <h4>Impulso Network - Online Community</h4>
        <p>
          I’m helping the Impulso Network community at Open-Source’s channel
          to engage members to contribute on open source projects,
          by the way, once time per month I organize the live with projects owner
          to explain their project and show getting to start.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={styles.content}
        contentArrowStyle={styles.arrow}
        date="2019 - Currently"
        iconStyle={styles.content}
        icon={<MdWork />}
      >
        <Title>Develop!</Title>
        <h4>São Paulo, Brazil</h4>
        <p>
          Developer main dashboard using JavaScript libraries like ReactJS, Redux, i18n,
          Recharts, React Router, ant-design, bootstrap, google-maps, etc.
          Currently, I’m migrating the dashboard from JS/Class Component to Typescript with
          Hook to improve component type check development.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2019"
        contentStyle={styles.border}
        iconStyle={styles.content}
        icon={<FaGraduationCap />}
      >
        <Title color="black">110% focused</Title>
        <h4 className="vertical-timeline-element-subtitle">
          Nano Degree - FrontEnd Developer (React) - Udacity
        </h4>
        <p>
          Focused on creating solutions and literally see my jobs online,
          I decided by working with one of the best libraries to do FrontEnds
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2017 - 2019"
        contentStyle={styles.border}
        iconStyle={styles.content}
        icon={<MdWork />}
      >
        <Title color="black">A.Telecom - Mid. System Analyst </Title>

        <h4 className="vertical-timeline-element-subtitle">São Paulo, Brazil</h4>
        <p>
          Tech Lead to structure developer team, I helped the technical manager to build solutions
          to Cisco ecosystems to automation and testing platform. Using AJAX,
          Django REST, JAVA and NodeJS.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2016"
        contentStyle={styles.border}
        iconStyle={styles.content}
        icon={<FaGraduationCap />}
      >
        <Title color="black">Go back to School</Title>
        <h4 className="vertical-timeline-element-subtitle">Post Degree - Business Manager - SENAC</h4>
        <p>
          With new challenges, I went back to University to take a formal education
          about Business strategy and department management.
        </p>
        <br />
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2016 - 2017"
        contentStyle={styles.border}
        iconStyle={styles.content}
        icon={<MdWork />}
      >
        <Title color="black">JEM - IT Coordinator</Title>
        <h4 className="vertical-timeline-element-subtitle">São Paulo, Brazil</h4>
        <p>
          IT coordinator with 4 employees and any IT responsibilities at the company
          maintenance CMR system, department costs, improve and maintains
          hardware and software licenses, etc.
        </p>
        <br />
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2011 - 2016"
        contentStyle={styles.border}
        iconStyle={styles.content}
        icon={<MdWork />}
      >
        <Title color="black">A.Telecom - Mid. System Analyst </Title>

        <h4 className="vertical-timeline-element-subtitle">São Paulo, Brazil</h4>
        <p>
          This was my Hello World to Technology career, initially work in internship IT Support
          I progressed my professional skills to network implementation and Lead Tech
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2011 - 2013"
        contentStyle={styles.border}
        iconStyle={styles.content}
        icon={<FaGraduationCap />}
      >
        <Title color="black">Study Very Hard</Title>
        <h4 className="vertical-timeline-element-subtitle">Degree - Databases Management - Sumaré</h4>

        <p>
          At the same time, I was studying in Degree education with a focus on Databases Management.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2011 - 2012"
        contentStyle={styles.border}
        iconStyle={styles.content}
        icon={<FaGraduationCap />}
      >
        <Title color="black">Study Hard</Title>
        <h4 className="vertical-timeline-element-subtitle">Certificate - Network Management - SENAI</h4>

        <p>
          Getting Started my formal Technology studying how to deploy, management
          and maintenance Network systems.
        </p>
      </VerticalTimelineElement>
      <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        icon={<FaStar />}
      />
    </VerticalTimeline>
  </Wrapper>
);

export default Timeline;
